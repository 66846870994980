import React, { useState } from 'react'
import { navigate } from "gatsby"
import {setItemToLStore} from '../helpers/hash'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm({ hideLabels, showPlaceholders, stacked, fontsize ,grayInputBg =true}) {
  const [formInputStates, setFormInputStates] = useState()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [formSubmitErrors, setFormSubmitErrors] = useState([])


  function handleChange(e) {
    setFormInputStates({ ...formInputStates, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    setSubmitInProgress(true)
    e.preventDefault()
   
    // if (!validateForm()) {
    //   window.scroll(0, 0)
    //   setSubmitInProgress(false)
    //   return
    // }
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'subject': ("dkrasniy " + formInputStates.Lastname + ", " + formInputStates.Firstname + " (" +  (new Date().toLocaleString()) + ")"),
        'form-name': form.getAttribute('name'),
        ...formInputStates,
        'landingURL': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('landing_p') ? window.localStorage.getItem('landing_p') : 'Not Provided'),
        'currentPath': (typeof window !== 'undefined' && window.localStorage && window.localStorage.getItem('current_p') ? window.localStorage.getItem('current_p') : 'Not Provided')

      }),
    })
      .then(() => {
        setItemToLStore('form', {date: new Date(), ...formInputStates})
        //after submit success, show spinner .05 sec 
        setTimeout(
          () => navigate(form.getAttribute('action')), 
          500
        );

      })
      .catch((error) => {
        alert(error)
        setSubmitInProgress(false)
      })
  }


  function validateForm() {
  
    let formValidateSuccess = true
    let formErrorFirstName = false
    let formErrorLastName = false
    let formErrorEmail = false
    let formErrorDesc = false
    
    let listOfErrors = [] 


    if (
      formInputStates.Firstname.length < 2 ||
      !formInputStates.Firstname.replace(/\s/g, '').length
    ) {
      listOfErrors.push("First name is a required field.")
      formValidateSuccess = false
      formErrorFirstName = true
    }
    if (
      formInputStates.Lastname.length < 2 ||
      !formInputStates.Lastname.replace(/\s/g, '').length
    ) {
      listOfErrors.push("Last name is a required field.")
      formValidateSuccess = false
      formErrorLastName = true
    }

   
    if (
      !formInputStates.email.match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\w{2,3})+$/)
    ) {
      listOfErrors.push("Email is a required field.")
      formValidateSuccess = false
      formErrorEmail = true
    } 
    if (
      formInputStates.description.length < 1 ||
      !formInputStates.description.replace(/\s/g, '').length
    ) {
      listOfErrors.push("Description cannot be left blank.")
      formValidateSuccess = false
      formErrorDesc = true
    }
  

    setFormSubmitErrors(listOfErrors)

    return formValidateSuccess
  }


  return (

<>
<div className="flex flex-col-reverse sm:flex-row items-start">
            <div className="px-6 md:px-0 flex flex-col w-full md:pr-8 mb-8">

              <h1 className="font-bold text-3xl md:text-4xl tracking-tight mb-1 text-black dark:text-white text-center sm:text-left">Work with me</h1>
              {/* <h2 className="text-gray-700 dark:text-gray-200 mb-4 text-center sm:text-left">yooo</h2> */}
             </div>
         
          </div>
    <form
      name="contact"
      method="post"
      action="/contact/success/"
      data-netlify="true"
      className='w-full px-6 md:px-0 max-w-xl'
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >

      
 
      {formSubmitErrors.length > 0 ? 
      <div className="shadow rounded-2xl mb-8 bg-gray-50 p-4">
        <span class="flex mb-2 text-red-600 font-semibold">
        <svg className="w-6 h-6 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
  <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
</svg> There were some problems with your submission.
        </span>
       
<ul className="p-0 m-0 list-none">

        {formSubmitErrors.map((error) => (<li className="block">- {error}</li>))}
        </ul>

      </div>: null} 
     

      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="currentPath" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-6">

        <div  className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          
          <label className={`block text-sm font-medium leading-5 text-gray-300  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'Firstname'}>First Name</label>
          <input
            className={`dark:bg-gray-800 bg-white w-full input p-3 text-gray-800 dark:text-white   rounded-2xl border-gray-100 dark:border-gray-800 border appearance-none focus:outline-none text-gray-100 focus:border-gray-400`}
            type={'text'}
            name={'Firstname'}
            onChange={handleChange}
            id={'Firstname'}
            required={true}
            placeholder={showPlaceholders ? 'First Name' : ''}
          />
        </div>

        <div  className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
        <label className={`block text-sm font-medium leading-5 text-gray-300  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'Lastname'}>Last Name</label>

          <input
            className={`dark:bg-gray-800 bg-white w-full input p-3 text-gray-800 dark:text-white    rounded-2xl border-gray-100 dark:border-gray-800 border appearance-none  text-gray-100 focus:outline-none focus:border-gray-400`}
            type={'text'}
            name={'Lastname'}
            onChange={handleChange}
            id={'Lastname'}
            required={true}
            placeholder={showPlaceholders ? 'Last Name' : ''}
          />

        </div>
        <div  className={`sm:col-span-6`}>
        <label className={`block text-sm font-medium leading-5 text-gray-300  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'email'}>Email</label>

          <input
            className={`dark:bg-gray-800 bg-white w-full input p-3 text-gray-800 dark:text-white  rounded-2xl border-gray-100 dark:border-gray-800 border appearance-none focus:outline-none focus:border-gray-400`}
            type={'email'}
            name={'email'}
            onChange={handleChange}
            id={'email'}
            required={true}
            placeholder={showPlaceholders ? 'Email' : ''}
          />

        </div>

       
        <div className="sm:col-span-6">
           <label className={`block text-sm font-medium leading-5 text-gray-300  mb-1 label ${hideLabels ? 'hidden' : ''} ${fontsize}`} htmlFor={'description'}>Tell me about your project</label>

          <textarea
            className={`dark:bg-gray-800 bg-white textarea w-full input p-3  text-gray-800 dark:text-white rounded-2xl border-gray-100 dark:border-gray-800 border appearance-none focus:outline-none focus:border-gray-400`}
            name={'description'}
            rows={5}
            onChange={handleChange}
            id={'description'}
            required={true}
            placeholder={showPlaceholders ? 'Tell me about your project' : ''}
          />

        </div>
      </div>
      <div className="field">
  <button className="mt-2 bg-gray-100 w-full cursor-pointer transition duration-150 ease-in-out bg-gray-800 hover:bg-gray-700 text-gray-200 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-xl   inline-flex items-center justify-center" type="submit">Send {submitInProgress ? <svg class="animate-spin ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg> : null} </button> 
      </div>
      
     
    </form>
    </>

  )
}

export default ContactForm