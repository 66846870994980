/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 
import React, {useState} from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {ThemeContext} from "./themeContext"
import Header from "./header"
import "./layout.css"
import { useLocalStorage } from '@mantine/hooks'

const Layout = ({ children }) => {

  const [value, setValue] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeContext.Provider value={{value, setValue}}>
      <div className={`${value == 'dark' ? 'dark' : ''}`}>
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900  ">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        className="px-4"
      >
        <main id="main">{children}</main>

        <footer className="text-gray-400 text-sm text-center flex items-center justify-center w-full relative max-w-4xl border-gray-200 dark:border-gray-700 mx-auto pt-8 pb-8 sm:pb-16  dark:text-gray-300 text-sm">

        © {new Date().getFullYear()} David Krasniy
        </footer>
      </div>
    </div>
    </div>
    </ThemeContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
