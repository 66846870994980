import * as React from "react"
import { motion, useViewportScroll, useTransform, m } from "framer-motion";
import Layout from "../../components/layout"
import ContactForm from "../../components/ContactForm"

const SuccessPage = () => (
  <Layout>
 
 <motion.div className='flex flex-col justify-center items-start max-w-4xl border-gray-200 dark:border-gray-700 mx-auto pb-16' 
        initial={{ opacity: 0, y: 2 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: .3 }, }}

        viewport={{ once: true }}>

<div className="flex flex-col-reverse sm:flex-row items-start">
            <div className="flex flex-col w-full md:pr-8 mb-8">

              <h1 className="font-bold text-3xl md:text-4xl tracking-tight mb-1 text-black dark:text-white text-center sm:text-left">Success!</h1>
              <h2 className="text-gray-700 dark:text-gray-200 mb-4 text-center sm:text-left">I've recieved your note and will be in touch soon.</h2>
             </div>
         
          </div>
 </motion.div>

   
  </Layout>
)

export default SuccessPage
