import React, {useState} from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {   MoonIcon, SunIcon } from '@heroicons/react/solid'
import { useLocation } from "@reach/router"
import { useLocalStorage } from '@mantine/hooks';

const Header = ({ siteTitle }) => {
  const [value, setValue] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });
 

  const toggleTheme = () => {
    setValue(value == 'dark' ? 'light' :  'dark')
  }
  const location = useLocation();
 
  let navitems = [
    { label: "home", slug: '/', active:  (location.pathname == '' || location.pathname == '/') && location.hash.length < 2 },
    { label: "work", slug: '/#work', active:  location.pathname.includes('/#work') || location.hash.includes("work") },
    { label: "contact", slug: '/contact', active:  location.pathname.includes('/contact') }
  
  ]
 
  return (
  <div className="flex flex-col justify-center px-6 sticky top-0 z-10 dark:header-blur-dark header-blur"> 
    <nav className="flex items-center justify-between w-full relative max-w-4xl border-gray-200 dark:border-gray-700 mx-auto py-4 md:py-6 text-gray-900  dark:text-gray-100">
      <a href="#main" className="screen-reader-only focus:outline-none focus:ring-2 focus:ring-gray-200 focus:bg-gray-200 text-gray-700 dark:text-gray-200 dark:focus:ring-gray-400 dark:focus:bg-gray-800 rounded-lg">Skip to content</a>
      <div className="-mx-3">{navitems.map((item, i) => <Link key={item.slug} className={`${item.active ? 'text-gray-800 dark:text-gray-200' : 'text-gray-600 dark:text-gray-400 '} font-semibold mx-1 px-3 py-2 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-800  focus:outline-none focus:ring-2 focus:ring-gray-400 dark:focus:bg-gray-800 dark:focus:text-gray-200 dark:hover:text-gray-200 transition-all`} to={item.slug}>{item.label}</Link>)}</div>
      <button onClick={()=>toggleTheme()} className="p-2 rounded-lg text-xs flex items-center font-semibold">
        {value =='dark' ? 
         <><SunIcon className="w-4 h-4 mr-1"/><span className="sr-only">Light</span></> : <><MoonIcon className="w-4 h-4 mr-1"/><span className="sr-only">Dark</span></> }
 
        </button>
    </nav>
  </div>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `David Krasniy`,
}

export default Header
